<template>
    <v-container fluid>
        <v-card height="100vh">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" />
            <Pareto_motivo_de_parada/>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import Pareto_motivo_de_parada from "./pareto_motivo_de_parada.vue";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-vue-calendars/styles/material.css';

import toolbarCharts from '../charts/toolbar/index.vue'

export default {
    name: "motivo_de_parada",
    components: {
        Pareto_motivo_de_parada,
        toolbarCharts
    },

    data: () => ({
    }),

    computed: {
        appCharts() {
            return useAppCharts();
        }
    },

    methods: {
    }
}
</script>