import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_toolbarCharts = _resolveComponent("toolbarCharts")
  const _component_Pareto_motivo_de_parada = _resolveComponent("Pareto_motivo_de_parada")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { height: "100vh" }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbarCharts, { onToolbarFiltros: _ctx.toolbarFiltros }, null, 8 /* PROPS */, ["onToolbarFiltros"]),
          _createVNode(_component_Pareto_motivo_de_parada)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}