import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_ejs_multiselect = _resolveComponent("ejs-multiselect")
  const _component_e_preset = _resolveComponent("e-preset")
  const _component_e_presets = _resolveComponent("e-presets")
  const _component_ejs_daterangepicker = _resolveComponent("ejs-daterangepicker")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, { height: "100%" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "2",
                class: "pl-4",
                style: {"align-content":"center"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, { transition: "scale-transition" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props, { style: {"background-color":"#121935"} }), {
                        default: _withCtx(() => [
                          _createTextVNode(" Recarregar em: " + _toDisplayString(_ctx.timeSelect), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, i) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: i,
                              style: {"cursor":"pointer"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  width: "100%",
                                  onClick: $event => ($options.onclickTime(item.title))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2",
                sm: "6",
                class: "align-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, { class: "font-weight-bold text-caption text-truncate pa-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Grupo:")
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_ejs_multiselect, {
                    id: 'multiselect1',
                    sortOrder: "ascending",
                    allowFiltering: $data.allowFiltering,
                    filtering: _ctx.filtering,
                    dataSource: $data.grupData,
                    fields: _ctx.fields,
                    placeholder: "Grupos",
                    mode: $data.delimiterMode,
                    modelValue: $data.selectedGroup,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.selectedGroup) = $event))
                  }, null, 8 /* PROPS */, ["allowFiltering", "filtering", "dataSource", "fields", "mode", "modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2",
                sm: "6",
                class: "align-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, { class: "font-weight-bold text-caption text-truncate pa-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Máquina:")
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_ejs_multiselect, {
                    id: 'multiselect2',
                    sortOrder: "ascending",
                    allowFiltering: $data.allowFiltering,
                    filtering: _ctx.filtering,
                    dataSource: $options.machineData,
                    fields: _ctx.fields,
                    placeholder: "Máquinas",
                    mode: $data.delimiterMode,
                    modelValue: $data.selectedMachine,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.selectedMachine) = $event))
                  }, null, 8 /* PROPS */, ["allowFiltering", "filtering", "dataSource", "fields", "mode", "modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2",
                sm: "6",
                class: "align-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, { class: "font-weight-bold text-caption text-truncate pa-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Turno:")
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_ejs_multiselect, {
                    id: 'multiselect3',
                    sortOrder: "ascending",
                    allowFiltering: $data.allowFiltering,
                    filtering: _ctx.filtering,
                    dataSource: $data.shiftData,
                    fields: _ctx.fields,
                    placeholder: "Turnos",
                    mode: $data.delimiterMode,
                    modelValue: $data.selectedShift,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.selectedShift) = $event))
                  }, null, 8 /* PROPS */, ["allowFiltering", "filtering", "dataSource", "fields", "mode", "modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "6",
                md: "4",
                class: "d-flex align-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ejs_daterangepicker, {
                    value: _ctx.defaultDateRange,
                    placeholder: "Selecionar Data",
                    format: 'dd/MM/yyyy',
                    modelValue: $data.selectedData,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.selectedData) = $event)),
                    onClick: $options.showCalendar
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_e_presets, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_e_preset, {
                            label: "Esta Semana",
                            start: $data.weekStartDate,
                            end: $data.weekEndDate
                          }, null, 8 /* PROPS */, ["start", "end"]),
                          _createVNode(_component_e_preset, {
                            label: "Este Mês",
                            start: $data.monthStartDate,
                            end: $data.monthEndDate
                          }, null, 8 /* PROPS */, ["start", "end"]),
                          _createVNode(_component_e_preset, {
                            label: "Último Mês",
                            start: $data.lastMonthStartDate,
                            end: $data.lastMonthEndDate
                          }, null, 8 /* PROPS */, ["start", "end"]),
                          _createVNode(_component_e_preset, {
                            label: "Último Ano",
                            start: $data.lastYearStartDate,
                            end: $data.lastYearEndDate
                          }, null, 8 /* PROPS */, ["start", "end"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["value", "modelValue", "onClick"]),
                  _createVNode(_component_v_btn, {
                    class: "ml-2 mr-5",
                    style: {"background-color":"#04b834"},
                    color: "white",
                    onClick: $options.onclickAplicar
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Aplicar")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}