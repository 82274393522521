<template>
    <motivo_de_parada />
</template>

<script>
import motivo_de_parada from '../../producao/charts/motivo_de_parada.vue';
export default {
    name: "paretoParadas",
    components: {
        motivo_de_parada
    }
}
</script>

<style scoped></style>