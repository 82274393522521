<template>
    <v-card >
        <v-toolbar height="100%">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="2" class="pl-4" style="align-content: center;">
                    <v-menu transition="scale-transition" >
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props" style="background-color: #121935;">
                                Recarregar em: {{ timeSelect }}
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                                <v-btn width="100%" @click="onclickTime(item.title)">{{ item.title }}</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="align-center">
                    <v-card-text class="font-weight-bold text-caption text-truncate pa-0">Grupo:</v-card-text  >
                    <ejs-multiselect :id="'multiselect1'" sortOrder="ascending" 
                        :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="grupData"
                        :fields="fields" placeholder="Grupos" :mode="delimiterMode" v-model="selectedGroup">
                    </ejs-multiselect>
                </v-col>

                <v-col cols="12" md="2" sm="6" class="align-center">
                    <v-card-text class="font-weight-bold text-caption text-truncate pa-0">Máquina:</v-card-text    >
                    <ejs-multiselect :id="'multiselect2'" sortOrder="ascending"
                        :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="machineData"
                        :fields="fields" placeholder="Máquinas" :mode="delimiterMode" v-model="selectedMachine">
                    </ejs-multiselect>
                </v-col>

                <v-col cols="12" md="2" sm="6" class="align-center">
                    <v-card-text class="font-weight-bold text-caption text-truncate pa-0">Turno:</v-card-text  >
                    <ejs-multiselect :id="'multiselect3'" sortOrder="ascending"
                        :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="shiftData"
                        :fields="fields" placeholder="Turnos" :mode="delimiterMode" v-model="selectedShift">
                    </ejs-multiselect>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                    <ejs-daterangepicker  :value="defaultDateRange" placeholder="Selecionar Data" :format="'dd/MM/yyyy'" v-model="selectedData"  @click="showCalendar" >
                    <e-presets>
                        <e-preset label="Esta Semana" :start='weekStartDate' :end='weekEndDate'></e-preset>
                        <e-preset label="Este Mês" :start='monthStartDate' :end='monthEndDate'></e-preset>
                        <e-preset label="Último Mês" :start='lastMonthStartDate' :end='lastMonthEndDate'></e-preset>
                        <e-preset label="Último Ano" :start='lastYearStartDate' :end='lastYearEndDate'></e-preset>
                    </e-presets>
                </ejs-daterangepicker>

            <v-btn class="ml-2 mr-5" style="background-color: #04b834;" color="white"
                @click='onclickAplicar'>Aplicar</v-btn>
                </v-col>
            </v-row>
        </v-toolbar>
    </v-card>
</template>

<script>
import { useAppCharts } from "../../../../stores/appCharts";

import { useAppMaquinas } from "../../../../stores/appMaquinas";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";

import { DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-vue-calendars';


export default {
    name: 'toolbarCharts',
    emits : ["toolbarFiltros"],
    components: {
        'ejs-multiselect': MultiSelectComponent,
        "ejs-daterangepicker": DateRangePickerComponent,
        'e-presets': PresetsDirective,
        'e-preset': PresetDirective
    },
    data() { 
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {

            weekStartDate:  new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),

            weekEndDate: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
            - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),

            monthStartDate:  new Date(new Date(new Date().setDate(1)).toDateString()),

            monthEndDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),

            lastMonthStartDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),

            lastMonthEndDate: new Date(new Date(new Date().setDate(0)).toDateString()),
            lastYearStartDate: new Date(year - 1, 1, 1).toDateString(),
            lastYearEndDate:  new Date(year - 1, 11, 31).toDateString(),
            //modal
            dialog: false,
            selectedData: [today, today],
            selectedGroup: '',
            selectedMachine: '',
            selectedShift: '',
            filter_maquinas: "",
            //multiselect
            defaultMode: 'Default',
            boxMode: 'box',
            delimiterMode: 'Delimiter',
            allowFiltering: true,
            grupData: ['Grupo1', 'Grupo2', 'Grupo3', 'Grupo4'],
            machineData: ['Máquina1', 'Máquina2', 'Máquina3', 'Máquina4'],
            shiftData: [],

            //Recarregar em: 
            items: [
                { title: 'OFF' },
                { title: 'AUTO' },
                { title: '5S' },
                { title: '10S' },
                { title: '30S' },
                { title: '1M' },
                { title: '5M' },
                { title: '10M' },
                { title: '15M' },
                { title: '30M' },
                { title: '1H' },
                { title: '2H' },
                { title: '1D' },
            ],
        };
    },
    mounted() {
        this.appCharts.listar_turnos().then(() => {
            this.$nextTick (() =>{
                this.shiftData = this.appCharts.shiftData;
                console.log('Turnos carregados:', this.shiftData);
            });
        }).catch(error => {
            console.error ("Erro ao carregar os turnos", error);
        })
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        machineData(){
            return this.resourceMaquinas.map(maquina => maquina.nome);
        },
        resourceMaquinas(){
            const filter = this.filter_maquinas.toUpperCase();
            return useAppMaquinas().resourceMaquinas.filter(maquina =>maquina.nome.toUpperCase().includes(filter));
        },
    },
    watch: {
        selectedData(newVal) {
            this.$emit('toolbarFiltros', 'data', newVal)
        },
        selectedGroup(newVal) {
            this.$emit('toolbarFiltros', 'group', newVal)
        },
        selectedMachine(newVal) {
            this.$emit('toolbarFiltros', 'machine', newVal)
        },
        selectedShift(newVal) {
            this.$emit('toolbarFiltros', 'shift', newVal)
        },
        
    },  
    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        },
        onclickAplicar() {
            // alert('Filtros aplicados com sucesso!');
        },
    
        openFilterDialog() {
            this.dialog = true;
        },

        showCalendar() {
            this.$refs.dateRangePicker.show(); // Mostra o calendário
        },
        
    },
};
</script>
<style scoped>

#wrapper {
        min-width: 230px;
    }

</style>