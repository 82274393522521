import { Mp, StatusFabrica } from "./../app/common/interfaceApp";
import { useAppMaquinas } from "./appMaquinas";
import { defineStore } from "pinia";

declare let frappe: any;

export const useAppCharts = defineStore({
  id: "appCharts",
  state: (): {
    showModal: Boolean;
    resourceMp: Mp[];
    showModalMp: Boolean;
    producao: Object;
    parada: Object;
    setup_manutencao_offline: Object;
    open_disponibilidade_resumida: Boolean;
    resourceStatusFabrica: Object;
    showGabriel: Boolean;
    showRebeka: Boolean;
    show_disponibilidade_resumida: boolean;
    show_motivo_de_parada: boolean;
    show_desempenho_da_empresa: boolean;
    shiftData: Object;

  } => ({
    showModal: false,
    resourceMp: [],
    showModalMp: false,
    producao: [],
    parada: [],
    setup_manutencao_offline: [],
    open_disponibilidade_resumida: false,
    resourceStatusFabrica: {
      trabalhando: [],
      parada: [],
      setup: [],
      manutencao: [],
      offline: [],
      currentMachine: null,
      tempExecucao: '00:00:00',
      timer: null,
    },
    showGabriel: false,
    showRebeka: false,
    show_disponibilidade_resumida: false,
    show_motivo_de_parada: false,
    show_desempenho_da_empresa: false,
    shiftData: [],
  }),

  actions: {
    async listar_turnos() {
      try {
        const { message } = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.listar_turnos"
        );
        console.log(message);
        if (Array.isArray(message)) {
          this.shiftData = message.map(turno => turno.name);
        } else {
          console.error("Resposta inesperada ao listar turnos:", message);
        }
      } catch (error) {
        console.error("Erro ao listar turnos:", error);
      }
    },
  async status_atual_fabrica() {

    const maquinasStore = useAppMaquinas();

    this.resourceStatusFabrica.offline = [];
    this.resourceStatusFabrica.trabalhando = [];
    this.resourceStatusFabrica.manutencao = [];
    this.resourceStatusFabrica.setup = [];
    this.resourceStatusFabrica.parada = [];

    maquinasStore.resourceMaquinas.forEach((recurso) => {

      const machine = {
        name: recurso.nome,
        op: recurso.ordem_de_producao,
        motivo_de_parada: recurso.apontamentos.find(
          (apt) => apt.status == "Aberto" && apt.tipo == "Parada"
        )
          ? recurso.apontamentos.find(
            (apt) => apt.status == "Aberto" && apt.tipo == "Parada"
          ).desc_motivo_parada
          : null,
        dt_inicio: recurso.apontamentos.find((apt) => apt.status == "Aberto")
          ? recurso.apontamentos.find((apt) => apt.status == "Aberto")
            .dt_inicio
          : null,
        nome_operador: recurso.apontamentos.find((apt) => apt.status == "Aberto")
          ? recurso.apontamentos.find((apt) => apt.status == "Aberto")
            .nome_operador
          : null,
        state_sensor: recurso.state_sensor,
        tipo: ""
      };

      const apontamento_aberto = {
        manutencao: null,
        setup: null,
      };

      recurso.apontamentos
        .filter((apontamento) => apontamento.status === "Aberto")
        .forEach((apontamento) => {

          apontamento_aberto.manutencao = apontamento.manutencao;
          apontamento_aberto.setup = apontamento.setup;
          machine.op = apontamento.ordem_de_producao;
          machine.motivo_de_parada = apontamento.desc_motivo_parada;
          machine.dt_inicio = apontamento.dt_inicio;
          machine.tipo = apontamento.tipo;
        });


      switch (machine.tipo) {
        case "Produção":
          this.resourceStatusFabrica.trabalhando.push(machine);
          break;
        case "Parada":
          if (apontamento_aberto.manutencao == 1) {
            this.resourceStatusFabrica.manutencao.push(machine);
          }

          if (apontamento_aberto.setup == 1) {
            this.resourceStatusFabrica.setup.push(machine);
          }

          if (apontamento_aberto.manutencao == 0 && apontamento_aberto.setup == 0) {
            this.resourceStatusFabrica.parada.push(machine);
          }
          break;
        default:
          this.resourceStatusFabrica.offline.push(machine);
          break;
      }

      if (machine.dt_inicio && this.currentMachine !== machine.name) {
        this.currentMachine = machine.name;
      }
    });
  }
},

  async get_all_apontamentos_abertos() {
  try {
    this.producao = [];
    this.parada = [];
    this.setup_manutencao_offline = [];
    const { message } = await frappe.call(
      "nxlite.nx_producao.page.apontamento_iot.controller.get_all_apontamentos_abertos"
    );

    this.producao = message.producao;
    this.parada = message.parada;
    this.setup_manutencao_offline = message.setup_manutencao_offline;
  } catch(err) {
    console.log("error do apontamento aberto", err);
  }
},

  update_apontamento_realtime(apt) {
  console.log(apt);
  const apontamento = apt.apontamento;
  if(apontamento.tipo == "Produção") {
    console.log(apontamento);
const foundIndex = this.producao.findIndex(
  (a) => a.name == apontamento.name
);
if (apontamento.status == "Aberto") {
  if (foundIndex == -1) {
    let new_producao = this.producao;
    new_producao.push(apontamento);
    this.producao = new_producao;
    return;
  } else {
    let new_producao = this.producao;
    new_producao[foundIndex] = apontamento;
    this.producao = new_producao;
    return;
  }
}
if (apontamento.status == "Fechado") {
  if (foundIndex != -1) {
    this.producao = this.producao.splice(foundIndex, 1);
  }
}
    }
if (apontamento.tipo == "Parada") {
  if (
    !apontamento.setup &&
    apontamento.status_sensor != "OFFLINE" &&
    !apontamento.manutencao
  ) {
    const foundIndex = this.parada.findIndex(
      (a) => a.name == apontamento.name
    );
    if (apontamento.status == "Aberto") {
      if (foundIndex == -1) {
        let new_parada = this.parada;
        new_parada.push(apontamento);
        this.parada = new_parada;
        return;
      } else {
        let new_parada = this.parada;
        new_parada[foundIndex] = apontamento;
        this.parada = new_parada;
        return;
      }
    }
    if (apontamento.status == "Fechado") {
      if (foundIndex != -1) {
        this.parada = this.parada.splice(foundIndex, 1);
      }
    }
  } else {
    //QUALQUER OUTRA COISA
    const foundIndex = this.setup_manutencao_offline.findIndex(
      (a) => a.name == apontamento.name
    );
    if (apontamento.status == "Aberto") {
      if (foundIndex == -1) {
        let new_setup_manutencao_offline = this.setup_manutencao_offline;
        new_setup_manutencao_offline.push(apontamento);
        this.setup_manutencao_offline = new_setup_manutencao_offline;
        return;
      } else {
        let new_setup_manutencao_offline = this.setup_manutencao_offline;
        new_setup_manutencao_offline[foundIndex] = apontamento;
        this.setup_manutencao_offline = new_setup_manutencao_offline;
        return;
      }
    }
    if (apontamento.status == "Fechado") {
      if (foundIndex != -1) {
        this.setup_manutencao_offline =
          this.setup_manutencao_offline.splice(foundIndex, 1);
      }
    }
  }
}
  },

  async listar_mp() {
  this.resourceMp = [];

  const res = await frappe.call(
    "nxlite.nx_producao.page.apontamento_iot.controller.list_apontamentos_parada"
  );

  res.message.forEach((motivo_de_parada: any) => {
    this.resourceMp.push({
      centro_de_custo: motivo_de_parada.centro_custo
        ? motivo_de_parada.centro_custo
        : null,
      desc_motivo_parada: motivo_de_parada.desc_motivo_parada,
      desc_recurso: motivo_de_parada.desc_recurso,
      dt_fim: motivo_de_parada.dt_fim,
      dt_inicio: motivo_de_parada.dt_inicio,
      manutencao: motivo_de_parada.manutencao,
      motivo_de_parada: motivo_de_parada.motivo_de_parada,
      name: motivo_de_parada.name,
      nome_operador: motivo_de_parada.nome_operador,
      operador: motivo_de_parada.operador,
      setup: motivo_de_parada.setup,
      status: motivo_de_parada.status,
      status_sensor: motivo_de_parada.status_sensor,
      tipo_turno: motivo_de_parada.tipo_turno,
      total_hr: motivo_de_parada.total_hr,
      turno: motivo_de_parada.turno,
    });
  });
},
});