<template>
    <v-container fluid>
        <v-card height="100%">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" />
            <v-divider class="mb-2 mt-2"></v-divider>
            <pie_disponibilidede_resumida />
            <tabela_disponibilidade_resumida />
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import tabela_disponibilidade_resumida from "../charts/tabela_disponibilidade_resumida.vue";
import pie_disponibilidede_resumida from "./pie_disponibilidede_resumida.vue";
import { useAppMaquinas } from "../../../stores/appMaquinas";
import toolbarCharts from '../charts/toolbar/index.vue'


export default {
    name: "disponibilidade_resumida",
    components: {
        tabela_disponibilidade_resumida,
        toolbarCharts,
        pie_disponibilidede_resumida,
    },
    data() {
        return {
            
        };
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    console.log('maquinas: ', pValue)
                    break;
                case 'shift':
                    console.log('turno: ', pValue)
                    break;
                case 'group':
                    console.log('grupo: ', pValue)
                    break;
            };
        }
    }
};
</script>